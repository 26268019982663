import * as React from 'react';
import { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/EditCalendar';
import DoneIcon from '@mui/icons-material/Done';
import CameraIcon from '@mui/icons-material/CenterFocusStrong';
import AccountIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import QuestionIcon from '@mui/icons-material/HelpOutlineRounded';
import HappyIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import ReportIcon from '@mui/icons-material/ReportProblemOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import { Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import axios from 'axios';
import Markdown from 'react-markdown';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";


const CustomColor = withStyles({
  root: {
    fontSize: 20,
    background: "-webkit-linear-gradient(45deg, #3f50b5 30%, #f44336 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
})(Typography);

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHJcXwn-I3CEgR-JEzJGr01BVcu0ADoIU",
  authDomain: "aivi-63ce1.firebaseapp.com",
  databaseURL: "https://aivi-63ce1.firebaseio.com",
  projectId: "aivi-63ce1",
  storageBucket: "aivi-63ce1.firebasestorage.app",
  messagingSenderId: "106223559889",
  appId: "1:106223559889:web:4572c8ffaf4c5db449d1f0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// main color: #9C27B0
const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledButton = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

export default function BottomAppBar() {

  // let defaultCrop = {unit: '%',width: 90,height: 20,x: 5,y: 40};
  let defaultCrop = {unit: 'px',width: window.innerWidth * 0.9,height: window.innerHeight * 0.3,x: window.innerWidth * 0.05,y: window.innerHeight * 0.35}

  const [imageURI, setImageURI] = useState('');
  const [isVertical, setIsVertical] = useState();
  const [finalImage, setFinalImage] = useState('');
  const [crop, setCrop] = useState(window.innerHeight/window.innerWidth);
  const [initCropFromUpload, setInitCropFromUpload] = useState(true);
  const [answer, setAnswer] = useState('');
  const inputFile = useRef(null) ;
  const [loading, setLoading] = useState();
  const [lag, setLag] = useState();
  const [inputFromKeyboard, setInputFromKeyBoard] = useState();
  const [recentTaskFromKeyboard, setRecentTaskFromKeyboard] = useState('');
  const [networkError, setNetWorkError] = useState();
  const [quotaError, setQuotaError] = useState();
  const [report, setReport] = useState();

  
  
  // This is to handle crop view
  const CropImg = () => {
    const onLoad = async (e) => {
      // console.log(window.innerHeight + ", " + window.innerWidth + ", " + e.target.naturalHeight + ", " + e.target.naturalWidth);
      if(initCropFromUpload) {
        if(e.target.naturalHeight / e.target.naturalWidth > window.innerHeight / window.innerWidth) {
          setIsVertical(true);
          let scaleWidth = e.target.naturalWidth * window.innerHeight / e.target.naturalHeight;
          defaultCrop.width = scaleWidth * 0.8;
          defaultCrop.x = scaleWidth * 0.1; 
        }
        else {
          setIsVertical(false);
          let scaleHeight = e.target.naturalHeight * window.innerWidth / e.target.naturalWidth;
          defaultCrop.height = scaleHeight * 0.3;
          defaultCrop.y = scaleHeight * 0.35; 
        }
        setCrop(defaultCrop);
        setInitCropFromUpload(false);
      }
      // if(e.target.naturalHeight / e.target.naturalWidth > window.innerHeight / window.innerWidth) setIsVertical(true);
    }
    return <img id='crop-image' src={imageURI} alt='crop-image' onLoad={onLoad} sx={{justifyContent:'center'}} style={{maxWidth: window.innerWidth, maxHeight: window.innerHeight, justifyContent: 'center', alignContent: 'center'}}/>
  }
 
  // This is to handle upload file
  const ImageInput = () => {
    const onChange = async e => {
      if (e.target.files && e.target.files.length > 0) {
        setImageURI(URL.createObjectURL(e.target.files[0]));
      }
      // console.log(defaultCrop);
      setCrop(defaultCrop);
      setInitCropFromUpload(true);
    }
    return <input ref={inputFile} type='file' onChange={onChange} style={{display: 'none'}}/>
  }

  // Back to main screen
  const backMainScreen = () => {
    logEvent(analytics, 'action_close', { name: 'action_close'});
    setImageURI('');
    setFinalImage('');
    setAnswer('');
    setInputFromKeyBoard(false);
    setLag(false);
    setLoading(false);
  }

  // Handle feedback
  const reportFeedback = (feedback, inputType, inputData, response) => {
    logEvent(analytics, 'feedback: ' + feedback, { name: 'report_feedback'});
    const jsonObject = {};
    jsonObject["feedback"] = feedback;
    jsonObject["inputType"] = inputType;
    jsonObject["input"] = inputData;
    jsonObject["response"] = response;
    handleApiCall('https://h2c-server-167533398884.asia-southeast1.run.app/feedback', JSON.stringify(jsonObject));
    setReport(true);
  }

  // handle call
  const handleApiCall = async (apiUrl, dataStr) => {
    let response = '';
    try{
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(() => setLag(true), 10000); // 10 seconds to show lag signal
      });

      const result = await axios.post(apiUrl, dataStr,
        {
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      );
      await Promise.race([timeoutPromise, result]);
      response = result.data;

    } catch (error) {
      if(error.status === 429) {
        logEvent(analytics, 'reach_quota_limit', { name: 'reach_quota_limit'});
        setQuotaError(true);
      }
      else setNetWorkError(true);
    }
    return response;
  }


  // This is to extract & perform the main task from image
  const cropPhoto = async () => {
    logEvent(analytics, 'handle_task_from_image', { name: 'handle_task_from_image'});
    setLoading(true);
    setLag(false);
    
   // Crop view
    const canvas = document.createElement('canvas');
    let image = document.getElementById('crop-image');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,    
    );

    // Get answer
    const base64Image = canvas.toDataURL('image/jpeg');
    const i = base64Image.indexOf('base64,');
    const base64data = base64Image.substring(i + 7);  
    const response = await handleApiCall('https://h2c-server-167533398884.asia-southeast1.run.app/image', base64data);
    
    setAnswer(response);
    setReport(false);
    setFinalImage(base64Image);
    setImageURI('');
    setLoading(false);  
  }


  // This is to perform the task from keyboard
  const performTextTask = async () => {
    logEvent(analytics, 'handle_task_from_keyboard', { name: 'handle_task_from_keyboard'});
    let taskDescription = document.getElementById('input-task-text-field').value;
    if(taskDescription !== '') {
      setLoading(true);
      setLag(false);
      const response = await handleApiCall('https://h2c-server-167533398884.asia-southeast1.run.app/text', taskDescription);
      setAnswer(response);
      setReport(false);
      setRecentTaskFromKeyboard(taskDescription);
      setInputFromKeyBoard(false);
      setFinalImage('');
      setLoading(false);
    } 
    else {
      backMainScreen();
    } 
  }

  return (

    <React.Fragment>
      <CssBaseline />
      {/* <Container sx={{position:'fixed', top: 10}} style={{backgroundImage:'url(h2c-logo.png)', height:window.innerHeight * 0.5}} > */}
      
      {
        
        <Box sx={{ width: '100%', height: '100%', position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent:'center',
          flexDirection: 'column',
        }} >
          {
            !inputFromKeyboard && answer ==='' && !networkError && !quotaError &&
            <CustomColor style={{fontSize:32}}>Vui Học Cùng Con!</CustomColor>
          }
          {
            !inputFromKeyboard && networkError && 
            <Alert sx={{borderRadius: 2}} severity="warning" onClose={() => {setNetWorkError(false)}}>
                Vui lòng kiểm tra đường truyền mạng.
            </Alert>
          }
          {
            !inputFromKeyboard && quotaError && 
            <Alert sx={{borderRadius: 2}} severity="warning" onClose={() => {setQuotaError(false)}}>
                Quá nhiều truy vấn cùng lúc. Vui lòng thử lại sau.
            </Alert>
          }
        </Box>
      }

      <Container sx={{overflowY: 'scroll'}} >

        {/* This dummy code is for space only */}
        <div style={{height: 10}}></div> 
        
        {/* Heading goes here... */}
        {
          !inputFromKeyboard && !networkError && !quotaError &&
          <Paper square sx={{pb: '0px', borderRadius: 2, border: 0.5, borderColor: '#9C27B0'}} elevation={5}>
            {
              answer === '' && !networkError && !quotaError &&
              <Alert severity="info" sx={{borderRadius: 2}}>
                <AlertTitle style={{fontWeight: 'bold', height: '18px'}}>Học Cùng Con</AlertTitle>
                <p style={{fontStyle: 'oblique', height: '8px'}}>1. Chụp hoặc tải ảnh bài tập từ thiết bị.</p>
                <p style={{fontStyle: 'oblique', height: '8px'}}>2. Tự nhập đề bài khi ảnh không nhận.</p>
                <p style={{fontStyle: 'oblique', height: '8px'}}>3. Giải tốt Toán và Tiếng Việt tiểu học.</p>
              </Alert>
            }
            
            {
              answer !== ''  && 
              <Typography variant="h6" gutterBottom component="div" sx={{ p: 1, pb: 0 }} style={{fontStyle: 'italic'}}>
                Đề bài:
              </Typography>
            }
            {
              answer !== '' && finalImage &&
              <img style={{maxWidth: window.innerWidth * 0.9, margin: 'auto', display: 'block', borderRadius: 10, padding: 5}} src={finalImage} alt='task'/>
            }
            {
              answer !== '' && !finalImage &&
              <Typography variant="h7" gutterBottom component="div" sx={{ p: 1, pb: 2}} style={{fontStyle: 'italic'}}>
                "{recentTaskFromKeyboard}"
              </Typography>
            }
          </Paper>
        }         

        
       

        {
          inputFromKeyboard &&
          <Modal open={inputFromKeyboard} style={{backdropFilter: "blur(8px)", justifyContent: 'center',alignContent:'center'}}>
            <div style={{height: window.innerHeight, backgroundColor: 'white'}}>
              <div style={{height: 20}}></div>
              <TextField
                sx={{width: window.innerWidth * 0.9, left: window.innerWidth * 0.05}}
                color="secondary"
                // fullWidth
                id="input-task-text-field"
                label="Nhập đề bài"
                multiline
                // maxRows={10}
                variant="standard"
                defaultValue={recentTaskFromKeyboard}
                onDragEnter={performTextTask}
              />
              
              <StyledButton color="secondary" aria-label="upload" onClick={performTextTask}>
                <CloudUploadIcon />
              </StyledButton>
              <IconButton color="inherit" aria-label="done" sx={{position: 'absolute', top:10, right:10}} onClick={performTextTask}>
                <DoneIcon />
              </IconButton>
            </div>
          </Modal>
        }

        {/* <img alignContent='center' width={window.innerWidth * 0.5} src='h2c-logo.png' alt='logo'/> */}

        {/* Crop goes here... */}
        {/* <Modal open={imageURI !== ''} style={{backdropFilter: "blur(8px)", justifyContent: 'center',alignContent:'center'}}>
          <div style={{height: window.innerHeight, justifyContent: 'center',alignContent:'center'}}>
            <ReactCrop crop={crop} onChange={c => {setCrop(c); console.log(c)}} >
              <CropImg/>
            </ReactCrop>
            <StyledButton color="secondary" aria-label="upload" onClick={cropPhoto}>
              <CloudUploadIcon />
            </StyledButton>
            <IconButton color="inherit" aria-label="close" sx={{position: 'absolute', top:10, right:10}} onClick={backMainScreen}>
              <CloseIcon />
            </IconButton>
          </div>
        </Modal>   */}

        <Modal open={imageURI !== '' && isVertical} style={{backdropFilter: "blur(8px)", justifyContent: 'center'}}>
          <Box sx={{ width: '100%', position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'top',
            flexDirection: 'column',
          }} >
            <ReactCrop crop={crop} onChange={c => {setCrop(c); console.log(c)}} >
              <CropImg/>
            </ReactCrop>
            <StyledButton color="secondary" aria-label="upload" onClick={cropPhoto}>
              <CloudUploadIcon />
            </StyledButton>
            <IconButton color="inherit" aria-label="close" sx={{position: 'absolute', top:10, right:10}} onClick={backMainScreen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal> 

        <Modal open={imageURI !== '' && !isVertical} style={{backdropFilter: "blur(8px)", alignContent: 'center'}}>
        <div style={{height: window.innerHeight, justifyContent: 'center',alignContent:'center'}}>
            <ReactCrop crop={crop} onChange={c => {setCrop(c); console.log(c)}} >
              <CropImg/>
            </ReactCrop>
            <StyledButton color="secondary" aria-label="upload" onClick={cropPhoto}>
              <CloudUploadIcon />
            </StyledButton>
            <IconButton color="inherit" aria-label="close" sx={{position: 'absolute', top:10, right:10}} onClick={backMainScreen}>
              <CloseIcon />
            </IconButton>
          </div>
        </Modal>

        

        {/* Loading */}
        <Modal open={loading} style={{backdropFilter: "blur(8px)", alignContent:'center'}}>
          <Box sx={{ width: '100%', position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }} >
            <LinearProgress color="secondary" sx={{width: '100%'}}/>
            {
              loading && lag && <p sx={{justifyContent:'center'}} style={{color:'#FFF4E5', fontStyle:'italic'}}>Đường truyền mạng không ổn định...</p>
              
            }
          </Box>
        </Modal>  

        
        
        {/* Show answer */}
        {
          answer &&
          <div>
            <Markdown>{answer}</Markdown>
            <div style={{height: 10}}></div>
            {
              !report &&
              <Box sx={{ width: '100%', position: 'absolute',
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'row'
              }} >
                <Button sx={{borderRadius: 5, textTransform: 'inherit'}} startIcon={<HappyIcon />} variant="outlined" color="info" size="small" onClick={() => reportFeedback("good", finalImage ? "image":"text", finalImage? finalImage:recentTaskFromKeyboard, answer)}>
                  Bài giải tốt
                </Button>
                &nbsp;&nbsp;
                <Button sx={{borderRadius: 5, textTransform: 'inherit'}} startIcon={<QuestionIcon />} variant="outlined" color="warning" size="small" onClick={() => reportFeedback("confusing", finalImage ? "image":"text", finalImage? finalImage:recentTaskFromKeyboard, answer)}>
                  Báo khó hiểu
                </Button>
                &nbsp;&nbsp;
                <Button sx={{borderRadius: 5, textTransform: 'inherit'}} startIcon={<ReportIcon />} variant="outlined" color="error" size="small" onClick={() => reportFeedback("bad", finalImage ? "image":"text", finalImage? finalImage:recentTaskFromKeyboard, answer)}>
                  Báo giải sai
                </Button>
              </Box>
            }
            {
              report &&
              <Button color="success" size="small" disabled>Cảm ơn bạn đã phản hồi!</Button>
            }
            <div style={{height: 150}}></div>
          </div>
        } 
          

        {/* Bottom Bar */}
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="account" disabled>
              <AccountIcon />
            </IconButton>
            <StyledFab color="secondary" aria-label="add" onClick={() => inputFile.current.click()}>
              <CameraIcon />
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="inherit" aria-label="edit" onClick={() => setInputFromKeyBoard(true)}>
              <EditIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <ImageInput/> {/* This is hidden input for uploading photo */}
      </Container>    
    </React.Fragment>
    
  );
}

